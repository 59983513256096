import { useMany } from "@refinedev/core";
import { List } from "@refinedev/mui";
import { Button, Grid, Typography } from "@mui/material"
import { useContext } from "react";
import { AllProductsWidget } from "components/products/all-products.widget";
import MainMenu from "components/welcome/Main.menu";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import ProductMenu from "components/welcome/Products.menu";
import { ProductsContext } from "contexts/products";
import HelpMenu from "components/welcome/Help.menu";
import { WebsiteFooter } from "components/footer";

export const PublicProductsList = () => {
  const products:any = useContext(ProductsContext)

  if (!products) {
      return <div>Loading...</div>;
  }

  if (products.isLoading) {
      return <div>Loading...</div>;
  }

  if (products.isError) {
      return <div>Something went wrong!</div>;
  }
  
  return (
    <>
    <HelpMenu />
      
      <MainMenu />
      <ProductMenu />
      <AllProductsWidget props={products.products} />
      <Grid container display={'flex'} justifyContent={'center'} style={{backgroundColor:'aliceblue', padding:30}}>
        {products.page > 1 &&
        <Grid item>
          <Button onClick={() => products.setPage(products.page - 1)} startIcon={<ArrowBack />}>Previous Page</Button>
        </Grid>
        }
        {products.products.length === 25 &&
          <Grid item>
            <Button onClick={() => products.setPage(products.page + 1)} endIcon={<ArrowForward />}>Next Page</Button>
          </Grid>
        }
      </Grid>
      <WebsiteFooter />
    </>
  )
};