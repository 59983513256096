import { Container, Grid, Typography } from "@mui/material"

export const WebsiteFooter = () => {
    return (
        <div style={{marginTop:20, paddingBottom:20, padding:10, backgroundColor:'whitesmoke', width:'100%'}}>
            <Grid style={{marginTop:10, marginBottom:40}} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={4} md={6}>
                    <img src="https://www.cryptocashfast.com/logo512.png" width="100" />
                    <Typography fontFamily={'Oleo Script'} variant="h5">CryptoCashFast Inc.</Typography>
                    <Typography variant="body1">&copy; 2021 - 2023</Typography>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                    <a href="https://info.cryptocashfast.com/mark-usage-terms">Mark Usage Terms</a>
                    <br />
                    <a href="https://info.cryptocashfast.com/data-processing-agreement">Data Processing Agreement</a>
                    <br />
                    <a href="https://info.cryptocashfast.com/services-agreement">Services Agreement</a>
                    <br />
                    <a href="https://info.cryptocashfast.com/prohibited-and-restricted-businesses">Prohibited and Restricted Businesses</a>
                    <br />
                    <a href="https://info.cryptocashfast.com/privacy-policy">Privacy Policy</a>
                    <br />
                    <a href="https://info.cryptocashfast.com/user-terms-of-service">User Terms of Service</a>
                </Grid>
            </Grid>
        </div>
    )
}