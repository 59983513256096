import * as React from 'react';
import Button from '@mui/material/Button';
import { Autocomplete, Backdrop, Container, Grid, TextField, Box } from '@mui/material';
import { Cancel, Help, Language, Public } from '@mui/icons-material';
import { useMany } from "@refinedev/core";
import { ProductsContext } from 'contexts/products';
import useWindowDimensions from 'utils/window.dimension';

export default function HelpMenu() {

  const products:any = React.useContext(ProductsContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const { data, isLoading, isError } = useMany({
    resource: "gift-cards/filters",
    ids:[],
    queryOptions: {
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  });

  let countryFilters = data?.data[0].filters ?? []
  
  const links = [{label:'English', icon: <Language />}, {label:'Help', url:'https://cryptocashfast.tawk.help/', icon:<Help />}]

  const goTo = (url:any) => {
    if(url) window.open(url, '_blank')
  }

  const { width } = useWindowDimensions()

  return (
    <>
    {width > 900 &&
    <div style={{backgroundColor:"#f8f9fa", minWidth:"100%"}}>
      <Container maxWidth="sm">
          <Grid container minHeight={50} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs display="flex" justifyContent="center" alignItems="center">
                  <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      color='secondary'
                  >
                    <Public />  {products?.country}
                  </Button>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                  >
                    <Box style={{backgroundColor:'white'}} display="flex" alignItems={'center'}>
                      <Cancel style={{fontSize:30, margin:10}} color='secondary' onClick={handleClose} />
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={(countryFilters)}
                        onChange={(e:any) => {
                          products?.setCountry(e.target.textContent)
                          handleClose()
                        }}
                        getOptionLabel={(option:any) => option.filterValue}
                        sx={{ width: 200, margin:1 }}
                        renderInput={(params) => <TextField style={{minHeight:10}} {...params} label="Country" />}
                      />
                    </Box>
                  </Backdrop>
              </Grid>
              {links.map((link, index) =>
              <Grid item key={index} xs display="flex" justifyContent="center" alignItems="center">
                  <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={() => goTo(link.url)}
                      color='secondary'
                  >
                    {link.icon} {link.label}
                  </Button>
              </Grid>
              )}
          </Grid>
      </Container>
    </div>
    }
    </>
  );
}