import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Autocomplete, Backdrop, Badge, Box, Button, Container, Grid, InputAdornment } from '@mui/material';
import { Cancel, Person, Search, SearchRounded, ShoppingCart } from '@mui/icons-material';
import { TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetIdentity } from "@refinedev/core";
import ShoppingCartPopUp from 'components/shopping_cart/PopUp.cart';
import { ShoppingCartContext } from 'contexts/shopping-cart';
import { ProductsContext } from 'contexts/products';
import useWindowDimensions from 'utils/window.dimension';

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export default function MainMenu() {
  let location = useLocation()
  let products_page = location.pathname.includes('products')
  let navigate = useNavigate()

  const products:any = React.useContext(ProductsContext)

  const [open, setOpen] = React.useState(false)
  const [backdrop, setBackdrop] = React.useState(false)
  const [keyword, setKeyword] = React.useState('')

  const { data: user } = useGetIdentity<IUser>();

  const cart:any = React.useContext(ShoppingCartContext)
  const { cart:shoppingCart, setCart } = cart

  const selectedValues = React.useMemo(
    () => products?.products.map((v:any) => v.name),
    [products],
  );

  const { width } = useWindowDimensions()

  return (
    <Container>
      {width > 900 ?
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            {!products_page && 
              <Grid item xs={4} display="flex" alignItems="center" style={{padding:10}}>
                <Typography style={{cursor:'pointer'}} onClick={() => navigate('/')} variant='h3' fontFamily={'Oleo Script'} sx={{ minWidth: 100 }}>CryptoCashFast</Typography>
              </Grid>
            }
            <Grid item xs={products_page ? 8 : 4} style={products_page ? {display:'flex', alignItems:'center'} : {display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Autocomplete
                options={selectedValues}
                fullWidth
                onChange={(e:any) => setKeyword(e.target.textContent)}
                renderInput={(params:any) => <div style={{display:'flex', alignItems:'center', height:50}}><TextField variant='standard' {...params} label="Search Products..." />
                <Button onClick={(e:any) => window.open(user ? window.location.origin+'/products/show/'+keyword: window.location.origin+'/buy-gift-cards-with-cryptocurrency/show/'+keyword)} style={{height:50}}><SearchRounded /></Button></div>}
              />
              
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="right" style={{marginTop:10}}>
                <Button sx={{ minWidth: 100 }} onClick={() => window.open('https://blog.cryptocashfast.com/')}>Blog</Button>
                <Button sx={{ minWidth: 100 }}  onClick={() => setOpen(!open)} startIcon={<Badge badgeContent={shoppingCart.length} color="secondary"><ShoppingCart /></Badge>}> Cart</Button>
                <ShoppingCartPopUp open={open} setOpen={setOpen} />
                {!user && <Button onClick={() => navigate('/login')}>Login</Button>}
            </Grid>
        </Grid>
        :
        <Grid container columns={{ xs: 4 }}>
            {!products_page && 
              <Grid item xs={2} display="flex" alignItems="center" style={{padding:10}}>
                <Typography style={{cursor:'pointer'}} onClick={() => navigate('/')} variant='h5' fontFamily={'Oleo Script'} sx={{ minWidth: 100 }}>CryptoCashFast</Typography>
              </Grid>
            }
            <Grid item xs={2} display="flex" justifyContent="right">
                <Button onClick={() => setBackdrop(!backdrop)}><SearchRounded /></Button>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={backdrop}
                  style={{alignItems:'baseline'}}
                >
                  <Box style={{backgroundColor:'white', width:'100%', padding:10}} display="flex" alignItems={'center'}>
                      <Cancel style={{fontSize:30, margin:10}} color='secondary' onClick={() => setBackdrop(!backdrop)} />
                      <Autocomplete
                        options={selectedValues}
                        fullWidth
                        onChange={(e:any) => setKeyword(e.target.textContent)}
                        renderInput={(params:any) => <div style={{display:'flex', alignItems:'center', height:50}}><TextField variant='standard' {...params} label="Search Products..." />
                        <Button onClick={(e:any) => window.open(user ? window.location.origin+'/products/show/'+keyword: window.location.origin+'/buy-gift-cards-with-cryptocurrency/show/'+keyword)} style={{height:50}}><SearchRounded /></Button></div>}
                      />
                  </Box>
                </Backdrop>
                <Button onClick={() => setOpen(!open)}>
                  <Badge badgeContent={shoppingCart.length} color="secondary"><ShoppingCart /></Badge>
                </Button>
                <ShoppingCartPopUp open={open} setOpen={setOpen} />
                {!user && <Button onClick={() => navigate('/login')}>
                  <Person />
                </Button>}
            </Grid>
        </Grid>
      }
    </Container>
  );
}