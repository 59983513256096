import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
    Show,
    TextFieldComponent as TextField,
    NumberField,
    EmailField,
    BooleanField,
    DateField,
} from "@refinedev/mui";
import { Typography, Stack, Card, CardContent, Grid } from "@mui/material";

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Cart
                </Typography>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {record?.cart.map((item:any, index:any) =>
                    <Grid item xs={2} sm={4} md={4} key={index}>
                        <Card key={index}>
                            <CardContent>
                                <img src={item.url} width={100} />
                                <TextField value={"Cart Item: "+index+1} />
                                <TextField value={item.name} />
                                <TextField value={"Card Value: "+item.amount+" "+item.currency} />
                                <TextField value={"Product ID: "+item.productId} />
                                <TextField value={"Quantity: "+item.quantity} />
                            </CardContent>
                        </Card>
                    </Grid>
                    )}
                </Grid>
                <Typography variant="body1" fontWeight="bold">
                    Recipients Email
                </Typography>
                <EmailField value={record?.email} />
                <Typography variant="body1" fontWeight="bold">
                    Phone Number
                </Typography>
                <TextField value={record?.phone_number} />
                <Typography variant="body1" fontWeight="bold">
                    Payment Status
                </Typography>
                <TextField value={record?.payment_status} />
                <Typography variant="body1" fontWeight="bold">
                    Fullfillment Status
                </Typography>
                <TextField value={record?.fullfillment_status} />
                <Typography variant="body1" fontWeight="bold">
                    Created At
                </Typography>
                <DateField value={record?.createdAt} />
                <Typography variant="body1" fontWeight="bold">
                    Updated At
                </Typography>
                <DateField value={record?.updatedAt} />
                <Typography variant="body1" fontWeight="bold">
                    ID
                </Typography>
                <TextField value={record?._id} />
            </Stack>
        </Show>
    );
};
