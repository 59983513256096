import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Typography, Grid, Container, Autocomplete, TextField } from '@mui/material';
import { useMany } from '@refinedev/core';
import { ArrowForward, Cancel, SearchRounded } from '@mui/icons-material';
import useWindowDimensions from 'utils/window.dimension';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function ProductMenu() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [buttonType, setButtonType] = React.useState('')
  const [categorySelected, setCategorySelect] = React.useState('')

  const { data, isLoading, isError } = useMany({
    resource: "gift-cards/filters",
    ids:[],
    queryOptions: {
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  });

  let categories = data?.data[3].filters ?? []

  const toggleDrawer =
    (anchor: Anchor, open: boolean, type: string) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      setButtonType(type)
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = () => {
    
    if(buttonType === "Buy Gift Cards") {
     return (
      <Container>
        <div style={{textAlign:'center'}} onClick={() => setState({ ...state, ['bottom']: false })}>
          <Cancel fontSize="large" color='primary' />
        </div>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{padding:20}}>
          <Grid item xs={4}>
              {categories.slice(0, 10).map((category:any, index:number) =>
                <div onClick={() => window.open(window.location.pathname.includes('products') ? window.location.origin+'/products/category/'+category.filterValue : window.location.origin+'/buy-gift-cards-with-cryptocurrency/category/'+category.filterValue)} style={{padding:10, cursor:'pointer', textAlign:'center'}} key={index} dangerouslySetInnerHTML={{ __html:  category.filterValue}} />
              )}
          </Grid>
          <Grid item xs={4}>
              {categories.slice(9, 20).map((category:any, index:number) =>
                <div onClick={() => window.open(window.location.pathname.includes('products') ? window.location.origin+'/products/category/'+category.filterValue : window.location.origin+'/buy-gift-cards-with-cryptocurrency/category/'+category.filterValue)} style={{padding:10, cursor:'pointer', textAlign:'center'}} key={index} dangerouslySetInnerHTML={{ __html:  category.filterValue}} />
                )}
          </Grid>
          <Grid item xs={4}>
              {categories.slice(19, 30).map((category:any, index:number) =>
                <div onClick={() => window.open(window.location.pathname.includes('products') ? window.location.origin+'/products/category/'+category.filterValue : window.location.origin+'/buy-gift-cards-with-cryptocurrency/category/'+category.filterValue)} style={{padding:10, cursor:'pointer', textAlign:'center'}} key={index} dangerouslySetInnerHTML={{ __html:  category.filterValue}} />
                )}
          </Grid>
        </Grid>
      </Container>
     )
    } else {
      return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{padding:20}}>
          <Grid item xs={4}>
              <Typography>Coming Soon.</Typography>
          </Grid>
        </Grid>
      )
  }
  };

  const { width } = useWindowDimensions()

  return (
    <>
    {width > 900 ?
    <Container>
      {(['Buy Gift Cards', 'Phone Refills']).map((anchor) => 
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer('bottom', true, anchor)}>
            <Typography fontFamily={'Oleo Script'} variant='h6'>
              {anchor}
            </Typography>
          </Button>
          <Drawer
            anchor={'bottom'}
            open={state['bottom']}
            onClose={toggleDrawer('bottom', false, anchor)}
          >
            {list()}
          </Drawer>
        </React.Fragment>
      )}
    </Container>
    :
    <>
    {window.location.pathname !== "/" &&
      <Autocomplete
        id="free-solo-demo"
        onChange={(e:any) => {
          setCategorySelect(e.target.textContent)
        }}
        options={categories.map((option:any) => option.filterValue.replace('amp;',""))}
        renderInput={(params:any) => <Container style={{padding:'40px', display:'flex', alignItems:'center', backgroundColor:'aliceblue'}}><TextField variant='standard' {...params} label="Search Categories" /><Button variant='contained' onClick={() => window.open(window.location.pathname.includes('products') ? window.location.origin+'/products/category/'+categorySelected : window.location.origin+'/buy-gift-cards-with-cryptocurrency/category/'+categorySelected)}><ArrowForward /></Button></Container>}
      />
    }
    </>
    }
    </>
  );
}