import { Button, Stack, Typography, Grid } from "@mui/material"
import HelpMenu from "components/welcome/Help.menu"
import MainMenu from "components/welcome/Main.menu"
import ProductMenu from "components/welcome/Products.menu"
import { StepOne, StepThree, StepTwo } from "components/welcome/three-step.widget"
import { primary_color } from "utils/style.css"
import { ProductsContextProvider } from 'contexts/products';
import { useNavigate } from "react-router-dom"
import { WebsiteFooter } from "components/footer"

export const WelcomePage = () => {
    let navigate = useNavigate()
    return (
        <ProductsContextProvider>
            <HelpMenu />

            <MainMenu />

            <ProductMenu />

            <Stack 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                style={{backgroundColor:primary_color, padding:50, height:400, textAlign:'center'}}
            >
                <Typography variant="h4" color={'white'}>
                    Live on Cryptocurrency
                </Typography>
                <Typography  variant="h5" color={'white'}>
                    Buy gift cards for anything under the sun.
                </Typography>
                <Typography  variant="h6" component="h2">
                    No account necessary.
                </Typography>
                <Button onClick={() => navigate('/buy-gift-cards-with-cryptocurrency')} variant="contained" color={'secondary'}>Browse 5,000+ Products</Button>
            </Stack>
            
            <Grid style={{backgroundColor:'grey', textAlign:'center', paddingBottom:20}} container  columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4}>
                    <Typography variant="h5" style={{padding:10}}>
                        Step 1.
                    </Typography>
                    <StepOne />
                    <Typography variant="body1">
                        Find a gift card.
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5" style={{padding:10}}>
                        Step 2.
                    </Typography>
                    <StepTwo />
                    <Typography variant="body1">
                        Pay using crypto.
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5" style={{padding:10}}>
                        Step 3.
                    </Typography>
                    <StepThree />
                    <Typography variant="body1">
                        Receive redemption code.
                    </Typography>
                </Grid>
            </Grid>

            <Grid style={{textAlign:'center', padding:50}} container  columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4}>
                    <Typography variant="h5">
                        Prepaid Cards
                    </Typography>
                    <img src={'https://storage.googleapis.com/encoded-hangout-322514.appspot.com/prepaid.svg'}  />
                    
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5">
                        e Wallets
                    </Typography>
                    <img src={'https://storage.googleapis.com/encoded-hangout-322514.appspot.com/eWalletBarcode.svg'} />
                    
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5">
                        Gift Cards
                    </Typography>
                    <img src={'https://storage.googleapis.com/encoded-hangout-322514.appspot.com/giftcards.svg'} />
                    
                </Grid>
            </Grid>
            <WebsiteFooter />
        </ProductsContextProvider>
    )
}