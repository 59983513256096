import { useContext, useEffect, useState } from "react";
import { useOne } from "@refinedev/core";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import HelpMenu from "components/welcome/Help.menu";
import MainMenu from "components/welcome/Main.menu";
import ProductMenu from "components/welcome/Products.menu";
import QuantityInput from "components/products/Quantity.input";
import ShoppingCartPopUp from "components/shopping_cart/PopUp.cart";
import { ShoppingCartContext } from "contexts/shopping-cart";
import { StepOne, StepThree, StepTwo } from "components/welcome/three-step.widget";

export const ProductShow: React.FC = () => {
  //const [id, setId] = useState(1);

  const { id } = useParams()

  const { data, isLoading, isError } = useOne({
    resource: "gift-cards/products",
    id,
  });

  const { data:btc,  } = useOne({
    resource: "crypto/rates/USD",
    id:1,
    queryOptions: {
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
    meta: {
        headers: {
            Authenticaiton:'thistokeniscreatedbyAlex'
        }
    }
  });

  const btc_rate = btc?.data

  const cart:any = useContext(ShoppingCartContext)
  const { cart:shoppingCart, setCart } = cart

  const [qty, setQty] = useState(1)
  const [card_amount, setCardAmount] = useState(0)

  let navigate = useNavigate()

  const [open, setOpen] = useState(false)

  // add product to cart
  const handleAddToCart = () => {
      if (!card_amount) 
      return alert('You much choose a card value first!')

      let product_in_cart = {
          name:product.name, 
          amount:card_amount,
          currency:product.currencyCode, 
          productId:product.productId, 
          quantity:qty, 
          url:product.imageUrl
      }
      
      setCart([...shoppingCart, product_in_cart])

      setOpen(true)
  }

  // add to cart and checkout 
  const handleBuyNow = () => {
      if (!card_amount) 
      return alert('You much choose a card value first!')
  
      handleAddToCart()
      navigate('/cart')
  }

  const product = data?.data[0];

  const BuyManager = () => {
    return (
      <Container style={{display:'flex', justifyContent:'center', backgroundColor:'white', padding:20}}>
        <Grid container spacing={1} style={{maxWidth:300}} display={'flex'} justifyContent={'center'}>
          <Grid item xs display={'flex'} justifyContent={'center'}>
              <TextField
                  id="outlined-select-currency"
                  select
                  label="Card Value"
                  helperText="Select amount"
                  >
                  {product.valueDenominations.split(',').map((option:any) => (
                      <MenuItem key={option} value={option} onClick={() => setCardAmount(option)}>
                      {option}{" "+product.currencyCode}
                      </MenuItem>
                  ))}
              </TextField>
          </Grid>
          <Grid item xs display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <QuantityInput qty={qty} setQty={setQty} />
          </Grid>
            <Typography style={{textAlign:'center', width:'100%'}}>
                {card_amount > 0 && (card_amount / Number(btc_rate)).toString().slice(0, 10)+" BTC"}
            </Typography>
            <Grid item>
                  <Button fullWidth variant='contained' onClick={handleBuyNow}>Buy Now</Button>
            </Grid>
            <Grid item>
                  <Button fullWidth variant='contained' onClick={handleAddToCart}>Add to Cart</Button>
                  <ShoppingCartPopUp open={open} setOpen={setOpen} />
            </Grid>
        </Grid>
      </Container>
    )
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <>
    <HelpMenu />
    <MainMenu />
    <ProductMenu />
    <Container>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} display={'flex'} justifyContent={'center'} style={{marginBottom:20}}>
        <Grid item xs={4} sm={4} md={4}>
          <Card>
            <img src={product.imageUrl} width={'100%'} />
          </Card>
        </Grid>
  
        <Grid item xs={4} sm={4} md={4}>
          <Typography variant="body1">{product.countryName} / {product.categories} / {product.name}</Typography>
          <Typography variant="h5">{product.name}</Typography>
          <div dangerouslySetInnerHTML={{ __html:  product?.description}} />
          {BuyManager()}
        </Grid>
    </Grid>
    <Card>
      <CardContent>
        <div dangerouslySetInnerHTML={{ __html:  product?.redemptionInstructions}} />
        <Typography variant="body2" color="text.secondary">
            Min. Purchase Amount : ${product.minValue}
            <br />
            Max Purchase Amount : ${product.maxValue}
        </Typography>
      </CardContent>
    </Card>
    <Container>
      <Grid style={{textAlign:'center', padding:20, backgroundColor:'#cdd3d8', display:'block', margin:'auto'}} container>
        <Grid item style={{marginBottom:20}}>
            <Typography variant="h4" style={{padding:10}}>
                Step 1.
            </Typography>
            <StepOne />
            <Typography variant="body1">
                Find a gift card.
            </Typography>
        </Grid>
        <Grid item style={{marginBottom:20}}>
            <Typography variant="h4" style={{padding:10}}>
                Step 2.
            </Typography>
            <StepTwo />
            <Typography variant="body1">
                Pay using crypto.
            </Typography>
        </Grid>
        <Grid item style={{marginBottom:20}}>
            <Typography variant="h4" style={{padding:10}}>
                Step 3.
            </Typography>
            <StepThree />
            <Typography variant="body1">
                Receive redemption 
                code.
            </Typography>
        </Grid>
      </Grid>
    </Container>
    </Container>
    </>
  );
};