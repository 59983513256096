import React, { useEffect, useState } from "react";
import {
    useDataGrid,
    ShowButton,
    List,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useGetIdentity } from "@refinedev/core";
import { Button, Container, Grid } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import useWindowDimensions from "utils/window.dimension";

export const OrderList: React.FC<IResourceComponentsProps> = () => {
    const [page, setPage] = useState(1)

    const { data: user } = useGetIdentity<any>();

    const { dataGridProps } = useDataGrid({
      meta: {
        headers :{
          email: user?.email
        }
      },
      filters: {
        permanent: [
          {
            field: "page",
            operator: "eq",
            value: page,
          },
        ],
      },
      queryOptions: {
        staleTime: 5 * 60 * 1000, // 5 minutes
      },
    });

    const { width } = useWindowDimensions()
    
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "email",
                headerName: "Email",
                minWidth: 200,
            },
            {
                field: "phone_number",
                headerName: "Phone Number",
                minWidth: 200,
            },
            {
                field: "cart",
                headerName: "Cart",
                minWidth: 150,
                renderCell: function render({ value }) {
                    return value?.length+" item(s)";
                },
            },
            {
                field: "payment_status",
                headerName: "Payment Status",
                minWidth: 200,
            },
            {
                field: "fullfillment_status",
                headerName: "Fullfillment Status",
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );

    return (
      <Container style={{width: width - 100}}>
        <List>
            <DataGrid hideFooterPagination {...dataGridProps} columns={columns} autoHeight />
            <Grid container display={'flex'} justifyContent={'center'}>
              {page > 1 &&
              <Grid item>
                <Button onClick={() => setPage(page - 1)} startIcon={<ArrowBack />}>Previous</Button>
              </Grid>
              }
              {dataGridProps.rows.length === 25 &&
                <Grid item>
                  <Button onClick={() => setPage(page + 1)} endIcon={<ArrowForward />}>Next</Button>
                </Grid>
              }
            </Grid>
        </List>
      </Container>
    );
};
