import { ExpandCircleDown, Favorite, Share } from '@mui/icons-material';
import { Grid, Card, CardContent, Typography, CardActions, Collapse, IconButton, styled, TextField, Button, MenuItem, ListItemButton, ListItemText } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import QuantityInput from './Quantity.input';
import { ShoppingCartContext } from 'contexts/shopping-cart';
import { useNavigate } from 'react-router-dom';
import ShoppingCartPopUp from 'components/shopping_cart/PopUp.cart';
import { useMany, useOne } from '@refinedev/core';
import useWindowDimensions from 'utils/window.dimension';

const ExpandMore = styled((props: any) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const Item = ( props:any ) => {
    const { data, isLoading, isError } = useOne({
        resource: "crypto/rates/USD",
        id:1,
        queryOptions: {
          staleTime: 5 * 60 * 1000, // 5 minutes
        },
        meta: {
            headers: {
                Authenticaiton:'thistokeniscreatedbyAlex'
            }
        }
    });

    const btc_rate = data?.data
    
    const cart:any = useContext(ShoppingCartContext)
    const { cart:shoppingCart, setCart } = cart

    const product = props.product

    let navigate = useNavigate()

    const [expanded, setExpanded] = useState(false)
    const [open, setOpen] = useState(false)

    const [qty, setQty] = useState(1)
    const [card_amount, setCardAmount] = useState(0)

    // add product to cart
    const handleAddToCart = () => {
        if (!card_amount) 
        return alert('You much choose a card value first!')

        let product_in_cart = {
            name:product.name, 
            amount:card_amount,
            currency:product.currencyCode, 
            productId:product.productId, 
            quantity:qty, 
            url:product.imageUrl
        }
        
        setCart([...shoppingCart, product_in_cart])

        setOpen(true)
    }

    // add to cart and checkout 
    const handleBuyNow = () => {
        if (!card_amount) 
        return alert('You much choose a card value first!')
    
        handleAddToCart()
        navigate('/cart')
    }

    useEffect(() => {
        if(!localStorage.getItem(product.currencyCode)){
            fetch(process.env.REACT_APP_API+'/crypto/convert/USD/'+product.currencyCode)
            .then(response => response.json())
            .then(json => {
                console.log(json)
                localStorage.setItem(product.currencyCode, JSON.stringify(json.rate))
            })
            .catch(error => console.error(error));
        }
    }, [])

    return (
        <Card sx={{ maxWidth: 300 }}>
            <img onClick={() => window.open(window.location.pathname.includes('products') ? window.location.origin+'/products/show/'+product.name : window.location.origin+'/buy-gift-cards-with-cryptocurrency/show/'+product.name)} style={{width:'100%', cursor:'pointer'}} src={product.imageUrl} />
            <Grid container spacing={1} alignItems={'center'} style={{padding:10}}>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Card Value"
                        helperText="Select amount"
                        >
                        {product.valueDenominations.split(',').map((option:any) => (
                            <MenuItem key={option} value={option} onClick={() => setCardAmount(option)}>
                            {option}{" "+product.currencyCode}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <QuantityInput qty={qty} setQty={setQty} />
                </Grid>
            </Grid>
            {localStorage.getItem(product.currencyCode) !== 'undefined' && localStorage.getItem(product.currencyCode) !== '0' ?
            <Typography style={{textAlign:'center'}}>
                {card_amount > 0 && ((card_amount * Number(localStorage.getItem(product.currencyCode))) / Number(btc_rate)).toString().slice(0, 10)+" BTC"}
            </Typography>
            :
            <Typography style={{textAlign:'center'}}>
                Not Available
            </Typography>
            }
            <Grid container spacing={1} style={{justifyContent:'center'}}>
                <Grid item>
                    <Button fullWidth variant='contained' onClick={handleBuyNow}>Buy Now</Button>
                </Grid>
                <Grid item>
                    <Button fullWidth variant='contained' onClick={handleAddToCart}>Add to Cart</Button>
                    <ShoppingCartPopUp open={open} setOpen={setOpen} />
                </Grid>
            </Grid>
            <CardContent>
                <Typography variant="h5">
                    {product?.name}
                </Typography>
                Delivery Type: {product?.deliveryType}
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <Favorite />
                </IconButton>
                <IconButton aria-label="share">
                    <Share />
                </IconButton>
                <ExpandMore
                expand={expanded}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
                >
                    <ExpandCircleDown />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {product.name}
                    </Typography>
                    <div dangerouslySetInnerHTML={{ __html:  product?.description}} />
                    <Typography variant="body2" color="text.secondary">
                        Min. Purchase Amount : ${product.minValue}
                        <br />
                        Max Purchase Amount : ${product.maxValue}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    )
}

export const AllProductsWidget = (props:any) => {
    const { data, isLoading, isError } = useMany({
        resource: "gift-cards/filters",
        ids:[],
        queryOptions: {
          staleTime: 20 * 60 * 1000, // 20 minutes
        },
    });

    let categories = data?.data[3].filters ?? []

    useEffect(() => {
      console.log(props)
    }, [props])

    const { width } = useWindowDimensions()
    
    return (
        <Grid container spacing={2}>
            {width > 900 &&
                <Grid item xs={2}>
                    <Card>
                    {categories.map((category:any, index:number) =>
                        <ListItemButton key={index} component="a" href={window.location.pathname.includes('products') ? window.location.origin+'/products/category/'+category.filterValue : window.location.origin+'/buy-gift-cards-with-cryptocurrency/category/'+category.filterValue} target='_blank'>
                            <ListItemText primary={<div dangerouslySetInnerHTML={{ __html:  category.filterValue}} />} />
                        </ListItemButton>
                        )}
                    </Card>
                </Grid>
            }
            <Grid item xs={width > 900 ? 10 : 12}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {props.props.map((x:any, index:any) =>
                    <Grid key={index} item>
                        <Item product={x} />
                    </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}