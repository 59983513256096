import { useState, createContext, useEffect } from "react";

export const ShoppingCartContext:any = createContext(null)

export function ShoppingCartContextProvider(props:any) {
    // get stored cart items
    let stored_items:any = localStorage.getItem('cart')

    // set init to stored cart items if there are any else an empty array
    let init = stored_items ? JSON.parse(stored_items) : []
    const [cart, setCart] = useState(init);

    // update stored cart as live cart is changed
    useEffect(() => {
      localStorage.setItem('cart', JSON.stringify(cart))
    }, [cart])
  
    return (
      <ShoppingCartContext.Provider value={{cart, setCart}}>
        {props.children}
      </ShoppingCartContext.Provider>
    );
  }