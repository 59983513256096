import { LinearProgress } from "@mui/material"
import { useState, useEffect } from "react"

export const CartTimer = () => {
    const [timer, setTimer] = useState<any>(100)
    
    useEffect(()=>{
        let myInterval = setInterval(() => {
                if (timer > 0) {
                    setTimer(timer - 1);
                }
                if (timer === 0) {
                    clearInterval(myInterval)
                } 
            }, 3000)
            return ()=> {
                clearInterval(myInterval);
              };
        });

    return (
        <>
            <LinearProgress variant="determinate" value={timer} style={{height:20}} />
            {timer * 3} Seconds
        </>
    )
}