import { useEffect, useState } from "react";
import { useMany, useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import HelpMenu from "components/welcome/Help.menu";
import MainMenu from "components/welcome/Main.menu";
import ProductMenu from "components/welcome/Products.menu";
import { AllProductsWidget } from "components/products/all-products.widget";
import { Button, Grid } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export const ProductCategory: React.FC = () => {
    const { category } = useParams()
    const [ limit, setLimit ] = useState(25)
    const [ page, setPage ] = useState(1)

    const { data, isLoading, isError } = useMany({
        meta: {
            headers: { category:category?.replace('&amp;', "&"), page, limit },
        },
        resource: "gift-cards/products/category",
        ids:[],
        queryOptions: {
            staleTime: 5 * 60 * 1000, // 5 minutes
        },
    });

    const products = data?.data ?? [];

    if (isLoading) {
    return <div>Loading...</div>;
    }

    if (isError) {
    return <div>Something went wrong!</div>;
    }

    return (
        <>
        <HelpMenu />
          
          <MainMenu />
          <ProductMenu />
          <AllProductsWidget props={products} />
          <Grid container display={'flex'} justifyContent={'center'} style={{backgroundColor:'aliceblue', padding:30}}>
            {page > 1 &&
            <Grid item>
              <Button onClick={() => setPage(page - 1)} startIcon={<ArrowBack />}>Previous</Button>
            </Grid>
            }
            {products.length === 25 &&
              <Grid item>
                <Button onClick={() => setPage(page + 1)} endIcon={<ArrowForward />}>Next</Button>
              </Grid>
            }
          </Grid>
        </>
    );
};