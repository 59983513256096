import { Cancel } from '@mui/icons-material';
import { Typography, Grid, Button } from '@mui/material';
import { ShoppingCartContext } from 'contexts/shopping-cart';
import { useContext, Fragment } from 'react';
import { useOne } from "@refinedev/core";
import * as React from 'react';

export const ShoppingCart = () => {
    const cart:any = useContext(ShoppingCartContext)

    const {cart:cart_items, setCart} = cart

    const [subtotal, setSubTotal] = React.useState(0)

    const handleRemoveItemFromCart = (item:any) => {
        let newCart:any = []
        cart_items?.filter((x:any) => x !== item && newCart.push(x))
        setCart(newCart)
    }

    const { data, isLoading, isError } = useOne({
        resource: "crypto/rates/USD",
        id:1,
        queryOptions: {
          staleTime: 5 * 60 * 1000, // 5 minutes
        },
        meta: {
            headers: {
                Authenticaiton:'thistokeniscreatedbyAlex'
            }
        }
      });

      const btc_rate = data?.data

      // find subtotal
      React.useEffect(() => {
        let local_total:any = []
        cart_items?.filter((x:any) => local_total.push(x.amount * x.quantity))

        setSubTotal(local_total.reduce((partialSum:any, a:any) => partialSum + a, 0))

        localStorage.setItem('btc_total', ((subtotal / Number(btc_rate)) + Fees(subtotal)).toString().slice(0, 10))
      }, [cart_items])

      // save total
      React.useEffect(() => {
        localStorage.setItem('btc_total', ((subtotal / Number(btc_rate)) + Fees(subtotal)).toString().slice(0, 10))
      }, [subtotal])

      // find fee
      const Fees = (amount:number) => (( amount * .03 ) / Number(btc_rate))
      
      React.useEffect(() => {
        {cart_items?.filter((product:any) => {
            if(!localStorage.getItem(product.currency)){
                fetch(process.env.REACT_APP_API+'/crypto/convert/USD/'+product.currency)
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    let rate = json.rate ? JSON.stringify(json.rate) : '0'
                    localStorage.setItem(product.currency, rate)
                })
                .catch(error => console.error(error));
            }
        })}
    }, [])
    
    return (
        <>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        {cart_items?.map((item:any, index:any) =>
        <Fragment key={index}>
            <Grid item xs={4}>
                <img
                    style={{width:'100%'}}
                    src={item.url}
                    alt={item.name}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography gutterBottom variant="body1" component="p">
                    {item.name} <br />
                    Value: {item.amount}{" "+item.currency} <br />
                    Qty: {item.quantity}<br />
                    <Button onClick={() => handleRemoveItemFromCart(item)} startIcon={<Cancel />}>Remove</Button>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                {localStorage.getItem(item.currency) !== 'undefined' && localStorage.getItem(item.currency) !== '0' ?
                <Typography gutterBottom variant="body1" component="p" style={{backgroundColor:'ghostwhite'}}>
                    {((item.amount*Number(localStorage.getItem(item.currency))) / Number(btc_rate)).toString().slice(0, 10)} BTC
                </Typography>
                :
                <Typography gutterBottom variant="body1" component="p" style={{backgroundColor:'red', color:'white'}}>
                    ITEM REMOVED
                </Typography>
                }
            </Grid>
        </Fragment>
        )}
        </Grid>
        
        <Grid container spacing={2} style={{backgroundColor:'ghostwhite'}}>
            <Grid item xs={8}>
                <Typography gutterBottom variant="body1" component="p">
                    Fees<br />
                    Total Estimate
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography gutterBottom variant="body1" component="p">
                    {Fees(subtotal).toString().slice(0, 10)} BTC<br />
                    {((subtotal / Number(btc_rate)) + Fees(subtotal)).toString().slice(0, 10)} BTC
                </Typography>
            </Grid>

        </Grid>
        </>
    )
}