import { useMany } from "@refinedev/core";
import { useState, createContext, useEffect } from "react";

export const ProductsContext:any = createContext(null)

export function ProductsContextProvider(props:any) {
    let local_country = localStorage.getItem('country')
    let stored_country = local_country ? JSON.parse(local_country) : 'USA'
    const [country, setCountry] = useState(stored_country)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(25)
    const { data, isLoading, isError } = useMany({
      meta: {
        headers: { country, page, limit },
      },
      resource: "gift-cards/products/country",
      ids:[],
      queryOptions: {
        staleTime: 5 * 60 * 1000, // 5 minutes
      },
    });
  
    const products = data?.data ?? [];

    useEffect(() => {
      localStorage.setItem('country', JSON.stringify(country))
    }, [country])
    
    return (
      <ProductsContext.Provider value={{country, setCountry, page, setPage, products, isLoading, isError}}>
        {props.children}
      </ProductsContext.Provider>
    );
  }