import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Navigate, useNavigate } from 'react-router-dom';

const steps = ['Contact info', 'Payment'];

export default function ShoppingCartStepper(props:any) {
  const [activeStep, setActiveStep] = React.useState(Number(props.page - 1));

  let navigate = useNavigate()

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  React.useEffect(() => {
    setActiveStep(Number(props.page - 1))
  }, [props])
  

  return (
    <Box sx={{ width: '100%' }}>
        <Button onClick={() => navigate('/')}>
            <Typography variant='h4' fontFamily={'Oleo Script'} sx={{ minWidth: 100 }}>CryptoCashFast</Typography>
        </Button>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">BTC</Typography>
            );
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <div style={{padding:10}}>
          {activeStep === 0 && <Typography sx={{ mt: 2, mb: 1 }}>Order Details</Typography>}
          {activeStep === 1 && <Typography sx={{ mt: 2, mb: 1 }}>BTC Payment</Typography>}
        </div>
      )}
    </Box>
  );
}