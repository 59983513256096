import { AuthPage } from "@refinedev/mui";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  let navigate = useNavigate()
  return (
    <AuthPage
      type="login"
      title={<img src="https://www.cryptocashfast.com/logo512.png" width={100} onClick={() => navigate('/')} />}
    />
  );
};
