import { ShoppingCart } from "components/shopping_cart/Cart"
import ShoppingCartStepper from "components/shopping_cart/Stepper"
import { Grid, Card, TextField, CardContent, FormGroup, Checkbox, FormControlLabel, Button, Typography, CircularProgress, Alert, AlertTitle, Container, Stack } from '@mui/material';
import { useRef, useState } from 'react'
import { useCreate } from "@refinedev/core";
import { CartTimer } from "components/shopping_cart/Timer";
import { WebsiteFooter } from "components/footer";

export const Cart = () => {

    const [page, setPage] = useState(1)
    const [email, setEmail] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [showPhoneField, setShowPhoneField] = useState(false)
    const [payment_address, setPaymentAddress] = useState<any>(null)
    let stored_cart = localStorage.getItem('cart')
    let cart = stored_cart  ? JSON.parse(stored_cart) : []

    const { mutate } = useCreate();

    const handlePage = () => {
        if (page === 1) setPage(2)
        if (page === 2) setPage(1)
    }

    const handleCheckout = () => {
        if (!email) return alert('Must enter an email')
        if (!email.includes('@') || !email.includes('.com')) return alert('Not an email.')

        handlePage()
        
        mutate({
            resource: "orders",
            values: {
            phone_number,
            email,
            cart,
            },
        },
        {
            onError: (error, variables, context) => {
            // An error occurred!
            console.log(error)
            },
            onSuccess: (data, variables, context) => {
            // Let's celebrate!
            console.log(data.data)
            setPaymentAddress(data.data)
            },
        },);

    }

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef<any>(null);

    function copyToClipboard(e:any) {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    };
    
    return (
        <>
        <ShoppingCartStepper page={page} />
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={6}>
                <Card>
                    <CardContent>
                        <ShoppingCart />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card>
                    <CardContent>
                        {page === 1 &&
                        <>
                            <FormGroup>
                                <TextField value={email} onChange={(e) => setEmail(e.target.value)} required type="email" label="Email for order status updates" />
                                {
                                    showPhoneField ?
                                    <TextField value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} style={{marginTop:10, marginBottom:20}} label="Phone Number for order status updates" />
                                    :
                                    <>
                                    <Typography style={{textAlign:'center'}}>
                                        Send gift card to a phone number * optional
                                    </Typography>
                                    <Button style={{marginBottom:20}} variant="outlined" onClick={() => setShowPhoneField(!showPhoneField)}>Add Phone Number</Button>
                                    </>
                                }
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Add me to the newsletter to receive news about new products and features" />
                                <FormControlLabel required control={<Checkbox />} label="I have read and agree with the CryptoCashFast Terms & Conditions and the Privacy Policy" />
                            </FormGroup>
                            <br />
                            <Button onClick={handleCheckout} fullWidth variant="contained">Continue</Button>
                        </>
                        }
                        {page === 2 &&
                        <>
                            <Button onClick={handlePage} fullWidth variant="contained">Change Contact Info</Button>
                            
                            { payment_address ?
                            <>
                                <div style={{marginTop:20}}>
                                    <CartTimer />
                                </div>
                                <div style={{textAlign:'center', margin:20}}>
                                    <img src={'https://api.qrserver.com/v1/create-qr-code/?size=250x250&data='+payment_address} />
                                </div>
                                <Typography variant="h5">
                                    Payment Details
                                </Typography>
                                {copySuccess}
                                <Stack >
                                    <Stack >
                                        <Typography>
                                        Payment unique address
                                        </Typography>
                                        
                                        <TextField  value={payment_address} />
                                    </Stack>
                                    
                                    <Stack >
                                        <Typography>
                                        Amount to pay
                                        </Typography>
                                        
                                        <TextField  value={localStorage.getItem('btc_total')+" BTC"} />
                                    </Stack>

                                </Stack>
                                <Container style={{maxWidth:300, marginTop:30}}>
                                    <Alert severity="warning">
                                        <AlertTitle>Send correct amount</AlertTitle>
                                        Overpayment will result in partial refund — <strong>Underpayment will result in full refund.</strong>
                                    </Alert>
                                </Container>
                            </>
                            :
                            <CircularProgress />
                            }
                        </>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <WebsiteFooter />
        </>
    )
}